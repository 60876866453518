import {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import {Loading} from "./components/Loading";
import {apiInterceptor} from "./api/api-interceptor";
import {useStore} from "./hooks/use-store";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {AppShell} from "./components/app/Shell";
import {AuthenticatedLayout} from "./layout/authenticated";
import {DocumentEditorWrapper} from "./pages/document_editor/document_editor_wrapper";
import {ResumeModalComponent} from "./pages/resume/resume-modal";
import { B2bSelectionModal } from './pages/b2b/b2b-selection';
import  CambriRichEditor  from './features/editor/editor-root';

import AustraliaSop from './pages/sops/australia_sop/australia_sop';
import { OpenApiStatus } from './api/open-api-status';
import { fetch_articles_by_tag } from './pages/utils';


/***************** LOAD ROUTES *****************/
// Set up all routers as lazy routes. THis is important because when the app loads, we want the landing page to split load quickly for SEO purposes.
// Hence make it default to setup all routes as lazy routes.

const LandingPage = lazy(() => import('./pages/home_page/index'))
const BlogPage = lazy(() => import('./pages/blogs/blogs'))
const SignIn = lazy(() => import('./pages/authentication/sign_in'))
const RequestResetPwd = lazy(() => import('./pages/authentication/request_reset_pwd'))
const ResetPwd = lazy(() => import('./pages/authentication/reset_pwd'))
const SignUp = lazy(() => import('./pages/authentication/sign_up'))
const DashboardOld = lazy(() => import('./pages/dashboard-old/dashboard'))
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'))
const VerifyEmail = lazy(() => import('./pages/authentication/verify-email'))
// const Profile = lazy(() => import('./pages/profile/profile'))
const ToolsList = lazy(() => import('./pages/tools/gen_tools_category'))
const Compose = lazy(() => import('./pages/tools/compose'))
const TextEditorScreen = lazy(() => import('./pages/tools/text-editor-screen'))
const TemplateEditor = lazy(() => import('./pages/tools/template-editor/template_editor'))
const Sop = lazy(() => import('./pages/sops/usa_sop/sop'))
const FinalOutput = lazy(() => import('./pages/sops/usa_sop/final_output_screen/final_output_screen'))
const UKFinalOutput = lazy(() => import('./pages/sops/uk_sop/final_output_screen/final_output_screen'))
const CanadaFinalOutput = lazy(() => import('./pages/sops/canada_sop/final_output_screen/final_output_screen'))
const LorFinalOutput = lazy(() => import('./pages/sops/LOR/final_output_screen/final_output_screen'))
const GermanyFinalOutput = lazy(() => import('./pages/sops/germeni_sop/final_output_screen/final_output_screen'))
const GermanySop = lazy(() => import('./pages/sops/germeni_sop/germany'))
const Login = lazy(() => import('./pages/LoginPage/LoginPage'))
const NotFound = lazy(() => import('./pages/LoginPage/NotFound'))
const Profile = lazy(() => import('./pages/new-profile/profile'))
const OnBoard = lazy(() => import('./pages/student-onBoarding/student-onBoarding'))
const PaymentsPage = lazy(()=>import('./pages/new-profile/payments/billing'))
const TransactionSummary= lazy(()=>import('./pages/new-profile/payments/transactionSummary'))
const UkSop = lazy(() => import('./pages/sops/uk_sop/uk_sop'))
const CanadaSop = lazy(() => import('./pages/sops/canada_sop/canada_sop'))
const Lor = lazy(() => import('./pages/sops/LOR/lor'))

const ResetPassword = lazy(() => import('./pages/LoginPage/ResetPassword'))
const ChangePassword = lazy(() => import('./pages/LoginPage/ChangePassword'))
const Tools = lazy(() => import('./pages/tools/new_tools_category'))
const Otp = lazy(() => import('./pages/LoginPage/OtpScreen/OtpScreen'))
const Library = lazy(() => import('./pages/dashboard/new-folder-content'))
const SopFile = lazy(() => import('./pages/sop-file/sop-file'))
const AdminTemplateEditor = lazy(() => import('./pages/admin/admin-template-editor'))
const AdminQuestionEditor = lazy(() => import('./pages/admin/admin-question-editor'))
const AdminCreditsEditor = lazy(() => import('./pages/admin/admin-credits-editor'))
const TermsAndConditions = lazy(() => import('./pages/profile/razer/TermsAndConditions'))
const ReturnandRefundPolicy = lazy(() => import('./pages/profile/razer/ReturnandRefundPolicy'))
const Contactus = lazy(() => import( './pages/profile/razer/contactus'))
const CancellationandRefundPolicy = lazy(() => import( './pages/profile/razer/CancellationandRefundPolicy'))
const Reports = lazy(() => import('./pages/Reports'))
const PrivacyPolicy = lazy(() => import( './pages/profile/razer/PrivacyPolicy'))
const ShippingandDeliveryPolicy = lazy(() => import( './pages/profile/razer/ShippingandDeliveryPolicy'))
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
/***********************************************/


export const ShellWrapper = ({component: Component, ...rest}) => {

  const {userStore,sopStore,dashboardStore} = useStore();
  const [apiStatus, setApiStatus] = useState(null);

  const navigate = useNavigate()
  const signout = () => {
    userStore.clearStoredDate()
    navigate('/login')
  }

  useEffect(() => {
    if(!userStore.isLoggedIn){
      sopStore.setFinalOutputScreen(false)
      navigate('/login')
      return
    }

    async function fetchData() {
      try {
        const openApiStatus = new OpenApiStatus();
        await openApiStatus.fetchOpenAIStatus();
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }

    fetchData();

    window.addEventListener(
      "token-expired",
      (e) => {
        console.log('Token Expired event triggerred', e)
        signout()
      },
      false
    );
  }, [])

  return (
    <>
      <AuthenticatedLayout>
        <Component {...rest} />
        <B2bSelectionModal />
        <ResumeModalComponent />
      </AuthenticatedLayout>
    </>
  );
};

export const PublicRoute = ({component: Component, ...rest}) => {
  return <Component {...rest} />;
};

export const App = () => {
  const {userStore} = useStore();

  apiInterceptor(userStore);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route index element={<Login/>}/>
          <Route path="/blog" element={<BlogPage/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/onboard" element={<OnBoard/>}/>
          <Route path="/sopfile" element={<ShellWrapper component={SopFile}/>}/>
          <Route path="/library" element={<ShellWrapper component={Library}/>}/>
          <Route path="/signupnew" element={<Login/>}/>
          <Route path="/otp/:id" element={<Otp/>}/>
          {/* <Route path="/tools" element={<Tools/>}/> */}
          <Route path="/resetpassword" element={<ResetPassword/>}/>
          <Route path='/changepassword' element={<ChangePassword/>}/>
          <Route path="/shell" element={<AppShell/>}/>
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/requestresetpwd" element={<RequestResetPwd/>}/>
          <Route path="/pwdreset" element={<ResetPwd/>}/>
          <Route path="/resetpwd" element={<ResetPwd/>}/>
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/verifyemail" element={<VerifyEmail/>}/>
          <Route path="/tools" element={<ShellWrapper component={Tools}/>}/>
          <Route path="/dashboard-old" element={<ShellWrapper component={DashboardOld}/>}/>
          <Route path="/dashboard" element={<ShellWrapper component={Tools}/>}/>
          <Route path="/doc/:id" element={<ShellWrapper component={DocumentEditorWrapper}/>}/>
          <Route path="/profile" element={<ShellWrapper component={Profile} selectedItem='Account'/>}/>
          <Route path="/profile/account" element={<ShellWrapper component={Profile} selectedItem='Account'/>}/>
          <Route path="/profile/usage" element={<ShellWrapper component={Profile} selectedItem='Usage'/>}/>
          {/* <Route path="/profile/billing" element={<ShellWrapper component={Profile} selectedItem='Billing'/>}/> */}
          <Route path="/profile/managetools" element={<ShellWrapper component={Profile} selectedItem='Manage Tools'/>}/>
          <Route path="/toolslist/compose/:id" element={<ShellWrapper component={Compose}/>}/>
          <Route path="/toolslist" element={<ShellWrapper component={ToolsList}/>}/>
          <Route path="/generate" element={<ShellWrapper component={TextEditorScreen}/>}/>
          <Route path="/tool/edit/:id" element={<ShellWrapper component={TemplateEditor}/>}/>
          <Route path="/sop" element={<ShellWrapper component={Sop}/>}/>
          <Route path="/sop/:id" element={<ShellWrapper component={Sop}/>}/>
          <Route path="/sop/:id/:folderid" element={<ShellWrapper component={FinalOutput}/>}/>
          <Route path="/admintemplateeditor" element={<ShellWrapper component={AdminTemplateEditor}/>}/>
          <Route path="/adminquestioneditor" element={<ShellWrapper component={AdminQuestionEditor}/>}/>
          <Route path="/Reports" element={<ShellWrapper component={Reports} />} />
          <Route path="/admincreditseditor" element={<ShellWrapper component ={AdminCreditsEditor}/>}/>
          <Route path="/termsandconditions" element={< TermsAndConditions />}/>
          <Route path="/returnandrefundpolicy" element={<ReturnandRefundPolicy/>}/>
          <Route path="/contactus" element={< Contactus />} />
          <Route path="/CancellationandRefundPolicy" element={< CancellationandRefundPolicy />} />
          <Route path="/PrivacyPolicy" element={<  PrivacyPolicy />} />
          <Route path="/ShippingandDeliveryPolicy" element={< ShippingandDeliveryPolicy />} />
          <Route path="*" element={<NotFound/>}/>
          <Route path="/profile/billing" element={<ShellWrapper component={Profile} selectedItem='Billing'/>}/>
          <Route path="/paymentspage" element={<PaymentsPage/>}/>
          <Route path="/transactionSummary" element={<TransactionSummary/>} />
          <Route path="/editor" element={<CambriRichEditor/>} />
          <Route path="/editor/:documentID/:folderID" element={<ShellWrapper component={CambriRichEditor}/>}/>
          <Route path="/uksop" element={<ShellWrapper component={UkSop}/>}/>
          <Route path="/uksop/:toolid" element={<ShellWrapper component={UkSop}/>}/>
          <Route path="/uksop/:id/:folderid" element={<ShellWrapper component={UKFinalOutput}/>}/>
          <Route path="/canadasop" element={<ShellWrapper component={CanadaSop}/>}/>
          <Route path='/australiasop/:id' element={<ShellWrapper component={AustraliaSop}/>}/>
          <Route path='/australiasop/:id/:folderid' element={<ShellWrapper component={FinalOutput}/>}/>
          <Route path='/germanysop/:id' element={<ShellWrapper component={GermanySop}/>}/>
          <Route path='/germanysop/:id/:folderid' element={<ShellWrapper component={GermanyFinalOutput}/>}/>
          <Route path="/germanysop" element={<ShellWrapper component={GermanySop}/>}/>
          <Route path="/canadasop/:id/:folderid" element={<ShellWrapper component={CanadaFinalOutput}/>}/>
          <Route path="/canadasop/:toolid" element={<ShellWrapper component={CanadaSop}/>}/>
          <Route path="/lor/:toolid" element={<ShellWrapper component={Lor}/>}/>
          <Route path="/lor/:id/:folderid" element={<ShellWrapper component={LorFinalOutput}/>}/>
          <Route path="/error" element={<ErrorPage/>} />
        </Routes>
      </Suspense>
      <ToastContainer/>
    </BrowserRouter>
  );
};
