import React, { Fragment, useEffect, useState } from 'react';
import { Dialog,  Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import UserAvatar from '../pages/authentication/UserAvatar';
import { useStore } from '../hooks/use-store';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import coinWhite from '../../src/icons/coinWhite.svg';
import TWModal from '../components/TWModal';
import RenameNewDocument from '../pages/dashboard/rename_new_doument';
import SopFile from '../pages/sop-file/sop-file';
import { checkApplicationStatus } from '../pages/utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ModalConfirmationDialog = observer((props) => {
  const {
    dashboardStore,
    sopStore,
    newContentStore,
    resumeStore,
    sopProfileSelectionStore,
    uKSopStore,
    canadaSopStore,
  } = useStore();
  const { modalWarningDialog } = dashboardStore;
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <Transition.Root show={modalWarningDialog} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      dashboardStore.setModalWarningDialog(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Discard Current SOP
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to discard current SOP? If not, please save all your
                        changes and then navigate away from SOP.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={async () => {
                      if (sopStore.toolId == 152 ) {
                        const stepName = sopStore.activeStep;
                        sopStore.markCompleted(stepName);

                        if ((sopStore.activeStep === 'Step 1' && newContentStore.libraryClick === false) ||(sopStore.activeStep === 'Step 1' && sopProfileSelectionStore.userSelectionClick === false)) {

                          await sopStore.save_sop_file(
                            sopProfileSelectionStore.sopProfileSelection?.folder_id,
                            sopProfileSelectionStore.current_sop_name,
                            newContentStore.activeFolder
                          );
                          if (location.pathname == '/library') {
                            await newContentStore.get_all_contents();
                          }
                        }
                        const index = sopStore.stepsArr.indexOf(sopStore.activeStep);
                        if (index + 1 <= 4) {
                          sopStore.set_active_step(sopStore.stepsArr[index + 1]);
                        }
                        dashboardStore.setModalWarningDialog(false);
                        dashboardStore.updateCurrentStatusForDashboardNav(
                          dashboardStore.modalConfirmNavigatePath
                        );
                      }
                      if (uKSopStore.toolId == 153) {
                        const stepNameUk = uKSopStore.activeStep;
                        uKSopStore.markCompleted(stepNameUk);
                        if ((uKSopStore.activeStep === 'Step 1' && newContentStore.libraryClick === false)|| (uKSopStore.activeStep === 'Step 1' && sopProfileSelectionStore.userSelectionClick === false)) {
                          await uKSopStore.save_sop_file(
                            sopProfileSelectionStore.sopProfileSelection?.folder_id,
                            sopProfileSelectionStore.current_sop_name,
                            newContentStore.activeFolder
                          );
                          if (location.pathname == '/library') {
                            await newContentStore.get_all_contents();
                          }
                        }
                        const index = uKSopStore.stepsArr.indexOf(uKSopStore.activeStep);
                        if (index + 1 <= 4) {
                          uKSopStore.set_active_step(uKSopStore.stepsArr[index + 1]);
                        }
                        dashboardStore.setModalWarningDialog(false);
                        dashboardStore.updateCurrentStatusForDashboardNav(
                          dashboardStore.modalConfirmNavigatePath
                        );
                      }
                      if (canadaSopStore.toolId == 154) {
                        const stepName = canadaSopStore.activeStep;
                        canadaSopStore.markCompleted(stepName);

                        if ((canadaSopStore.activeStep === 'Step 1'&& newContentStore.libraryClick=== false)||(canadaSopStore.activeStep === 'Step 1'&& sopProfileSelectionStore.userSelectionClick=== false)) {
                          await canadaSopStore.save_sop_file(
                            sopProfileSelectionStore.sopProfileSelection?.folder_id,
                            sopProfileSelectionStore.current_sop_name,
                            newContentStore.activeFolder
                          );
                          if (location.pathname == '/library') {
                            await newContentStore.get_all_contents();
                          }
                        }
                        const index = canadaSopStore.stepsArr.indexOf(canadaSopStore.activeStep);
                        if (index + 1 <= 4) {
                          canadaSopStore.set_active_step(canadaSopStore.stepsArr[index + 1]);
                        }
                        dashboardStore.setModalWarningDialog(false);
                        dashboardStore.updateCurrentStatusForDashboardNav(
                          dashboardStore.modalConfirmNavigatePath
                        );
                      }
                      if (sopStore.toolId == 155 ) {
                        const stepName = sopStore.activeStep;
                        sopStore.markCompleted(stepName);

                        if ((sopStore.activeStep === 'Step 1' && newContentStore.libraryClick=== false)||(sopStore.activeStep === 'Step 1' && sopProfileSelectionStore.userSelectionClick=== false)) {
                          await sopStore.save_sop_file(
                            sopProfileSelectionStore.sopProfileSelection?.folder_id,
                            sopProfileSelectionStore.current_sop_name,
                            newContentStore.activeFolder
                          );
                          if (location.pathname == '/library') {
                            await newContentStore.get_all_contents();
                          }
                        }
                        const index = sopStore.stepsArr.indexOf(sopStore.activeStep);
                        if (index + 1 <= 4) {
                          sopStore.set_active_step(sopStore.stepsArr[index + 1]);
                        }
                        dashboardStore.setModalWarningDialog(false);
                        dashboardStore.updateCurrentStatusForDashboardNav(
                          dashboardStore.modalConfirmNavigatePath
                        );
                      }
                    }}
                  >
                    Save & Proceed
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      dashboardStore.setModalWarningDialog(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

// @ts-ignore
export const AuthenticatedLayout = observer(({ children }) => {
  const {
    userStore,
    dashboardStore,
    sopStore,
    uKSopStore,
    canadaSopStore,
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [showRenameDocModal, setShowRenameDocModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const rechargeHandler = () => {
    window.open('/paymentspage');
  };

  const OpenApiStatus = localStorage.getItem('openApiStatus')

  const topbar = (
    <nav
      id="navbar"
      className={`z-[2] h-20 fixed ${OpenApiStatus == 'true' ? 'top-12' : 'top-0'} flex bg-white px-4 items-center flex-row justify-between border-b border-gray-300 py-4`}
      style={{ width: dashboardStore.showDashboardSideBar ? 'calc(100vw - 320px)' : '100vw' }}
    >
      <div className="flex flex-row items-center">
        <button
          id="btnSidebarToggler"
          type="button"
          className="text-2xl text-black ml-[5px]"
          onClick={() => {
            dashboardStore.toggleShowDashboardSideBar();
          }}
        >
          {dashboardStore.showDashboardSideBar ? (
            <></>
          ) : (
            <div className="h-10 w-10 flex items-center justify-center rounded-full bg-[#F8F9FA]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                />
              </svg>
            </div>
          )}
        </button>
        <div className={`flex flex-col ${dashboardStore.showDashboardSideBar ? 'ml-4' : 'ml-6'}`}>
          <h3 className={`font-bold font-Avenir text-xl text-[#292D35]`}>
            {dashboardStore.screenName}
          </h3>
          {(dashboardStore.screenName.toLowerCase() == 'statement of purpose' ||
            dashboardStore.screenName == 'Letter of Recommendation') && (
            <div className="flex">
              <div className="flex items-center mt-1">
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center mr-5">
        <div
          className="group cursor-pointer relative"
            onClick={() => {
              window.open('https://cambri-help-document.web.app', '_blank', 'noopener');
            }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
          <span
            className="group-hover:opacity-100 w-[136px] z-50 bottom-0 px-4 py-2 transition-opacity bg-gray-800 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-full opacity-0 mx-auto"
          >
            Help Document
          </span>
        </div>
        <UserAvatar />
      </div>
    </nav>
  );

  const sidebar = (
    <div id="containerSidebar" className="z-[999]">
      <div className="navbar-menu relative z-[999]">
        <nav
          id="sidebar"
          className={classNames(
            dashboardStore.showDashboardSideBar ? 'show' : 'hidden',
            'fixed h-full flex w-3/4 -translate-x-full flex-col overflow-x-hidden overflow-y-auto bg-[#131720] pt-6 pb-2 sm:max-w-xs lg:w-80'
          )}
        >
          <div className="pl-[24px]">
            <div className="flex flex-row items-center">
              <button
                type="button"
                className="text-xl text-white mr-4"
                onClick={() => {
                  dashboardStore.setShowDashboardSideBar(false);
                }}
              >
                <div className="h-10 w-10 flex items-center justify-center rounded-full bg-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#FEFEFE"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                    />
                  </svg>
                </div>
              </button>
              <img src="/cambri-logo-white.png" style={{ height: '36px' }} alt="Cambri" />
            </div>
          </div>

          <div className="mt-10">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {dashboardStore.dashboardNavigation.map((item) => (
                    <li key={item.name}>
                      {(userStore.role && userStore.role.includes('admin')) || item.isVisible ? (
                        <div
                          className={classNames(
                            item.current
                              ? 'bg-purple-800 text-[#F1F2F4]'
                              : 'text-[#ABB2BE] hover:text-white hover:bg-purple-800',
                            'pl-[37px] py-3 group items-center flex gap-x-3 rounded-md text-[16px] leading-6 cursor-pointer'
                          )}
                          onClick={() => {
                            navigate(item.href);
                            //Check for current path
                            if (
                              location.pathname === '/sop' ||
                              location.pathname === '/uksop/153' ||
                              location.pathname === '/canadasop/154'||
                              location.pathname === '/germanysop/156'
                            ) {
                              if (
                                (sopStore.selecteduniversitiy &&
                                  sopStore.selectedDegree &&
                                  sopStore.selectedCourse) ||
                                (uKSopStore.selecteduniversitiy &&
                                  uKSopStore.selectedDegree &&
                                  uKSopStore.selectedCourse) ||
                                (canadaSopStore.selecteduniversitiy &&
                                  canadaSopStore.selectedDegree &&
                                  canadaSopStore.selectedCourse)
                              ) {
                                dashboardStore.setModalWarningDialog(true);
                              } else {
                                dashboardStore.setModalWarningDialog(false);
                              }
                              dashboardStore.setModalConfirmNavigatePath(item.name);
                            } else {
                              dashboardStore.updateCurrentStatusForDashboardNav(item.name);
                              // navigate('/sop');
                              sopStore.setFinalOutputScreen(false);
                            }
                            dashboardStore.setShowDashboardSideBar(false);
                          }}
                        >
                          <img className="mr-2 cursor-pointer" src={item.icon} alt={item.name} />
                          {item.name}
                        </div>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>

          <div className="mt-auto">
            <div className="px-4">
              <div className="rounded-lg bg-[#150437] border border-[#4E5664] text-white p-6 flex flex-col">
                <div className="flex flex-row">
                  <img src={coinWhite} alt="coins.svg" />
                  <div className="text-sm pl-2">Credits</div>
                </div>
                <div className="font-bold text-[24px] pt-1 pb-4">
                  {userStore.credits === 0 ? 0 : userStore.credits}
                </div>
                <button
                  className="bg-purple-700 text-white text-[14px] p-4 uppercase rounded-lg cursor-pointer"
                  onClick={rechargeHandler}
                >
                  Recharge
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="mx-auto lg:ml-80"></div>
    </div>
  );

  useEffect( () => {
    const fetchApplicationStatus = async () => {
      const messages = await checkApplicationStatus();
      if(messages.length > 0){
        dashboardStore.setErrorMessages(messages);
        navigate('/error');
      }
    };

    fetchApplicationStatus();
    const intervalId = setInterval(fetchApplicationStatus, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const main = <main className={`${OpenApiStatus === 'true' ? 'mt-28 ': 'mt-10'}`}>{children}</main>;
  return (
    <>
      <div className="flex flex-row bg-[#f8f9fd] overflow-hidden">
        {dashboardStore.showDashboardSideBar && sidebar}
        <div className="w-full">
          {OpenApiStatus === 'true' && (
            <div
              className={`z-[2] h-12 ${
                dashboardStore.showDashboardSideBar ? 'w-[85%]' : 'w-full'
              } fixed top-0 flex px-8 items-center bg-red-100 text-red-700`}
            >
                <p>
                  It looks like Openai is currently experiencing a temporary outage. some of the
                  features in the application might be affected For more details and updates, please
                  refer to the{' '}
                  <a className="underline" target="_blank" href={'https://status.openai.com'}>
                    <strong>status page</strong>
                  </a>
                  . We appreciate your patience and understanding!
                </p>
              </div>
            // </div>
          )}
          {topbar}
          {main}
        </div>
      </div>
      <ModalConfirmationDialog />
      <SopFile isOpen={isOpen} setIsOpen={setIsOpen} />
      <TWModal
        show={showRenameDocModal}
        children={
          <RenameNewDocument
            setShowRenameDocModal={setShowRenameDocModal}
            activeDoc={{ name: sopStore.sopName, id: sopStore.sopId, isfile: true }}
          />
        }
        onClose={() => setShowRenameDocModal(false)}
      />
    </>
  );
});
