import apiSauce from "./api-sauce";

export class ResumeAPI {

  async get_structured_data_from_cv(payload): Promise<any> {
    const formData = new FormData();
    formData.append("file",payload.url);
    return await apiSauce.apisauce.post(`gen/extract-textv1?user_name=${payload.user_name}`, formData);
  }

}
